// @ts-check

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useAsync } from 'react-async-hook';
import { Alert, Col } from 'reactstrap';
import { format } from 'fecha';
import { useTranslation } from 'react-i18next';

import PresaleDisplay from '../../views/Presale/PresaleDisplay';
import { Loading } from '../Loading';
import { useStudio } from './Provider';
import { AdVideo } from '../Ad/Video/Video';
import ChannelCover from '../Channel/Cover/Cover';
import { MediaManagerScreen } from '../MediaManager/Screen';
import { useAuthentication } from '../Authentication/Authentication';
import { ResourceAccessEnterPassword } from '../ResourceAccess/EnterPassword';
import { ResourceAccessBuy } from '../ResourceAccess/Buy';
import { useAsyncErrorLog } from '../../lib/hooks';
import { useMediaManagerPreference } from '../MediaManager/MediaManager';
import { ButtonLoading, ButtonPill } from '../Button';
import { ModerationBanned } from '../Channel/Moderation/Banned';
import { useStudioSchedule } from '../StudioSchedule/Context';
import { ResourceAccessRole } from '../../lib/ResourceAccessRole';
import { ChannelSocketWrapper } from '../Channel/SocketWrapper';
import { ResourceAccessPiPoints } from '../ResourceAccess/PiPoints';
import { AudienceRestriction } from '../ResourceAccess/AudienceRestriction';
import { ResourceAccessPricePerMinuteWarning } from '../ResourceAccess/PricePerMinuteWarning';
import { ResourceAccessForceJoin } from '../ResourceAccess/ForceJoin';
import { useUpdateStudioSchedule } from '../../api-hooks/studio/schedule';
import { Path, getChannelLink, getLink } from '../../RoutePath';
import { StudioStatus } from '../StudioSchedule/helper';
import { useFetchDefaultOrganization } from '../../api-hooks/public/organization';
import { useProfile } from '../Profile/ProfileContext';
import { ResourceAccessNickname } from '../ResourceAccess/Nickname';
import { useWsConnectorError } from '../Channel/SocketWrapper/hooks';

export const ADS_LOCAL_STORAGE_KEY = 'beeyou_adsAlreadyWatched';
const isBeeyou = import.meta.env.VITE_PROJECT === 'beeyou';

const StudioLoaderContent = ({ children, title }) => (
	<div className="d-flex flex-column w-100 app-content-100 align-items-center justify-content-center">
		{title && <h2 className="text-center p-4 m-0">{title}</h2>}
		<div className="w-100 h-100 overflow-hidden">
			{children}
		</div>
	</div>
);

StudioLoaderContent.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
};

StudioLoaderContent.defaultProps = {
	title: undefined,
};

const StudioLoaderPending = ({ channel, title }) => (
	<StudioLoaderContent title={title}>
		<ChannelCover className="h-100" channel={channel} />
	</StudioLoaderContent>
);

StudioLoaderPending.propTypes = {
	channel: PropTypes.shape({}).isRequired,
	title: PropTypes.string.isRequired,
};

const StudioLoaderCentered = ({ children }) => (
	<div className="d-flex h-100 py-4 align-items-center justify-content-center">
		<Col md="6" lg="5" xl="4">
			{children}
		</Col>
	</div>
);

StudioLoaderCentered.propTypes = {
	children: PropTypes.node.isRequired,
};

const StudioLoaderError = ({
	error,
	notFoundComponent: NotFoundComponent,
}) => {
	const { t } = useTranslation();
	const isNotFoundError = error?.response?.status === 404;
	const { isLoggedIn } = useAuthentication();

	if (isNotFoundError && isLoggedIn) {
		return <NotFoundComponent />;
	}

	const errorMessage = !isLoggedIn
		? t('Studio.Loader.error.signUp', { studioLabel: isBeeyou ? 'Studio' : 'Command Center' })
		: error?.response?.data?.message || t('Global.error');

	return (
		<StudioLoaderCentered>
			<Alert color="danger">
				{errorMessage}
			</Alert>
		</StudioLoaderCentered>
	);
};

StudioLoaderError.propTypes = {
	error: PropTypes.shape({
		response: PropTypes.shape({
			data: PropTypes.shape({
				message: PropTypes.string,
			}),
			status: PropTypes.number,
		}),
	}).isRequired,
	notFoundComponent: PropTypes.elementType.isRequired,
};

const StudioCancelled = () => {
	const { t } = useTranslation();
	return (
		<StudioLoaderCentered>
			<Alert className="text-center" color="warning">
				{t('Studio.Loader.warning.cancelled')}
			</Alert>
		</StudioLoaderCentered>
	);
};

const StudioPlanned = () => {
	const { t } = useTranslation();
	const { studio } = useStudio();
	const { user } = useAuthentication();
	const { canStartStudio } = useStudioSchedule();

	const ownStudio = user?.sub === studio.owner._id;
	const { startAt } = studio;
	const startAtDate = startAt && new Date(startAt);
	const isStudioWaitingForController = !studio.hasControllerJoined;
	const waitingStart = !startAtDate || startAtDate < new Date() || isStudioWaitingForController;
	const { mutate: startStudio, error, isLoading } = useUpdateStudioSchedule();

	const handleStartStudio = useCallback(() => {
		startStudio({ id: studio._id, studioSchedule:	{ startImmediately: true } });
	}, [startStudio, studio._id]);

	useAsyncErrorLog({ error });

	const errorMessage = error?.response?.data?.message
		|| t('Global.error');

	return (
		<>
			<PresaleDisplay studio={studio} />
			<StudioLoaderCentered>
				<Alert className="text-center" color="info">
					<p className="mb-0">
						{waitingStart
							? t('Studio.Loader.waitingForHostToStartMeeting')
							: (
								<>
									{t('Studio.Loader.willStart')}:<br />
									<b>{format(new Date(startAt), t('Global.DateFormat.dateAndTimeWithTimeZone'))}</b>
								</>
							)}
					</p>
					{ownStudio && (
						<p className="mt-3 text-center">
							{error && (
								<span className="d-block">
									{errorMessage}
								</span>
							)}
							<ButtonLoading
								component={ButtonPill}
								loading={isLoading}
								onClick={handleStartStudio}
								disabled={!canStartStudio}
							>
								{t('Studio.Loader.startNow')}
							</ButtonLoading>
						</p>
					)}
				</Alert>
			</StudioLoaderCentered>
		</>
	);
};

/**
 * @param {{
 * 	code: string,
 * 	defaultStudio?: object, // TODO: replace with actual IStudioDto
 *  requestParams?: object,
 *  role?: ResourceAccessRole,
 * }} param0
 */
export const useStudioLoader = ({
	code,
	defaultStudio,
	requestParams,
	role = ResourceAccessRole.PUBLIC,
}) => {
	const { user, guest } = useAuthentication();
	const userOrGuest = user || guest;
	const {
		accessStatus,
		channel,
		requestStudioByCode,
		resetStudioState,
		setStudioState,
		studio,
	} = useStudio();

	// Load studio
	const {
		error,
		loading,
		execute: requestStudio,
		result,
	} = useAsync(
		async () => {
			const data = await requestStudioByCode(
				code,
				role,
				{ ...(requestParams || {}) },
			);
			return {
				data,
				/**
				 * Return the nickname with witch the studio was requested
				 * in order to compare with initial nickname for guest
				 * to show or not the update nickname modal
				 */
				nickname: userOrGuest?.nickname,
			};
		},
		[
			code,
			requestParams,
			role,
			requestStudioByCode,
			userOrGuest, // trigger re-request when user changes
		],
	);

	useAsyncErrorLog({ error });

	useEffect(() => {
		if (defaultStudio && code === defaultStudio.code) {
			setStudioState(defaultStudio);
		}
	}, [code, defaultStudio, setStudioState]);

	useEffect(() => () => {
		resetStudioState();
	}, [code, resetStudioState]);

	return useMemo(() => ({
		accessStatus,
		channel,
		error,
		nickname: result?.nickname,
		requestStudio,
		loading,
		studio,
	}), [
		accessStatus,
		channel,
		error,
		requestStudio,
		result?.nickname,
		loading,
		studio,
	]);
};

const StudioLoaderForbiddenAccess = ({
	accessStatus,
	requestStudio,
	role,
	setRequestParams,
	studio,
	joinAutomatically,
}) => {
	const { t } = useTranslation();
	const { owner: channel } = studio;
	const { isLoggedIn, isGuest } = useAuthentication();
	const [password, setPassword] = useState('');

	const isStudioRoleRequested = [
		ResourceAccessRole.OPERATOR,
		ResourceAccessRole.HOST,
	].includes(role);

	const handleSubmitPassword = useCallback((/** @type {string} */pw) => {
		setPassword(pw);
		setRequestParams({ password: pw });
	}, [setRequestParams]);

	const forceJoin = useCallback(() => {
		setRequestParams({ forceJoin: true, password });
	}, [setRequestParams, password]);

	const isAuthenticated = isLoggedIn || isGuest;

	const doesRequireLogin = !isLoggedIn
		&& (
			accessStatus.doesRequireAudienceRestriction // Could work with guest
			|| accessStatus.doesRequireBought
			|| accessStatus.doesRequirePiPoints
			|| accessStatus.doesRequirePricePerMinuteWarning
		);

	const doesRequireGuest = !doesRequireLogin
		&& !isAuthenticated
		&& role === ResourceAccessRole.PARTICIPANT;

	// Redirect non hosts to participant screen
	if (isStudioRoleRequested) {
		return (
			<Redirect
				to={getLink(Path.STUDIO_PARTICIPANT, { hashtag: channel.hashtag, code: studio.code })}
			/>
		);
	}

	if (accessStatus.doesRequirePassword) {
		return (
			<StudioLoaderCentered>
				<ResourceAccessEnterPassword
					onSubmit={handleSubmitPassword}
					resourceType="STUDIO"
					resourceId={studio._id}
					role={role}
				/>
			</StudioLoaderCentered>
		);
	}

	if (accessStatus.isBanned) {
		return (
			<ModerationBanned
				channel={channel}
				onBanEnded={requestStudio}
				until={accessStatus.bannedUntil}
			/>
		);
	}

	if (doesRequireLogin || doesRequireGuest) {
		return (
			<StudioLoaderCentered>
				<ResourceAccessNickname hideGuest />
			</StudioLoaderCentered>
		);
	}

	if (accessStatus.doesRequireAudienceRestriction) {
		return (
			<StudioLoaderCentered>
				<AudienceRestriction
					resourceType="STUDIO"
				/>
			</StudioLoaderCentered>
		);
	}

	if (accessStatus.doesRequireBought) {
		return (
			<StudioLoaderCentered>
				<ResourceAccessBuy
					resourceData={studio}
					resourceId={studio._id}
					resourceType="STUDIO"
					role={role}
					onSuccess={requestStudio}
				/>
			</StudioLoaderCentered>
		);
	}

	if (accessStatus.doesRequirePiPoints) {
		return (
			<StudioLoaderCentered>
				<ResourceAccessPiPoints
					resourceType="STUDIO"
				/>
			</StudioLoaderCentered>
		);
	}

	if (accessStatus.doesRequirePricePerMinuteWarning) {
		return (
			<StudioLoaderCentered>
				<ResourceAccessPricePerMinuteWarning
					resourceType="STUDIO"
					resourceData={studio}
					role={role}
					onSuccess={requestStudio}
				/>
			</StudioLoaderCentered>
		);
	}

	if (accessStatus.doesRequireForceJoin) {
		return (
			<StudioLoaderCentered>
				<ResourceAccessForceJoin
					onForceJoin={forceJoin}
				/>
			</StudioLoaderCentered>
		);
	}

	return (
		<StudioLoaderCentered>
			<Alert color="danger">
				{t(
					isLoggedIn
						? 'Studio.Loader.error.forbiddenAccess'
						: 'Studio.Loader.error.signUp', { studioLabel: isBeeyou ? 'Studio' : 'Command Center' },
				)}
			</Alert>
		</StudioLoaderCentered>
	);
};

StudioLoaderForbiddenAccess.propTypes = {
	accessStatus: PropTypes.shape({
		bannedUntil: PropTypes.string,
		doesRequireForceJoin: PropTypes.bool,
		doesRequireBought: PropTypes.bool,
		doesRequirePiPoints: PropTypes.bool,
		doesRequirePassword: PropTypes.bool,
		doesRequireAudienceRestriction: PropTypes.bool,
		doesRequirePricePerMinuteWarning: PropTypes.bool,
		isBanned: PropTypes.bool,
	}).isRequired,
	studio: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		code: PropTypes.string.isRequired,
		owner: PropTypes.shape({
			hashtag: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	requestStudio: PropTypes.func.isRequired,
	role: PropTypes.oneOf(Object.values(ResourceAccessRole)).isRequired,
	joinAutomatically: PropTypes.bool,
};

StudioLoaderForbiddenAccess.defaultProps = {
	joinAutomatically: false,
};

const StudioLoaderAllowedAccess = ({
	nicknameSeen,
	pendingTitle,
	readyComponent: ReadyComponent,
	readyTitle,
	role,
	setInitialNickname,
	studio,
	joinAutomatically,
}) => {
	const [adEnded, setAdEnded] = useState(false);
	const [mediaManagerSeen, setMediaManagerSeen] = useState(false);
	const [listStudioAds, setListStudioAds] = useState(
		JSON.parse(localStorage.getItem(ADS_LOCAL_STORAGE_KEY)) || [],
	);

	const { profile } = useProfile();
	const { data: defaultOrganization } = useFetchDefaultOrganization();

	const {
		alwaysDisplayMediaChecked,
		saveUserConfiguredMedia,
		isMediaConfigured,
	} = useMediaManagerPreference();
	/* Create state to initial value to avoid hiding unexpectedly the media manager screen
	when the user clicks on the checkbox "always display media manager" */
	const initialAlwaysDisplayMediaManagerScreen = useRef(alwaysDisplayMediaChecked);

	const { _id: studioId, owner: channel, status } = studio;
	const { _id: channelId } = channel;

	const isStudioPending = status === StudioStatus.PENDING;
	const isStudioRunning = status === StudioStatus.RUNNING;

	const handleConfirmMediaManagerScreen = useCallback(() => {
		setMediaManagerSeen(true);
		if (!isMediaConfigured) saveUserConfiguredMedia();
	}, [isMediaConfigured, saveUserConfiguredMedia]);

	const handleAdEnded = () => {
		setAdEnded(true);
		const newListStudioAds = [...listStudioAds, { studioId, date: new Date().toISOString() }];
		setListStudioAds(newListStudioAds);
		localStorage.setItem(ADS_LOCAL_STORAGE_KEY, JSON.stringify(newListStudioAds.filter(
			(ad) => new Date(ad.date).getTime() > new Date().getTime() - (48 * 60 * 60 * 1000),
		)));
	};

	// If studio code changes, reset ad status to watch again an ad
	useEffect(() => {
		setAdEnded(false);
	}, [studioId]);

	const closeAd = adEnded || listStudioAds.some((ad) => ad.studioId === studioId);

	if (!closeAd && isBeeyou && profile?.organization?._id === defaultOrganization?._id) {
		return (
			<StudioLoaderContent title={!isStudioRunning ? pendingTitle : readyTitle}>
				<AdVideo
					beeyouAd
					canSkip={isStudioRunning}
					onEnded={handleAdEnded}
					onSkip={handleAdEnded}
				/>
			</StudioLoaderContent>
		);
	}

	// if (isStudioPending) {
	// 	return (
	// 		<StudioLoaderPending
	// 			channel={studio.owner}
	// 			title={pendingTitle}
	// 		/>
	// 	);
	// }

	/* Show media manager screen if
	- the user has checked "always show media manager"
	- OR it's the first time they're seeing the media manager */
	const showMediaManagerScreen = !mediaManagerSeen
		&& (initialAlwaysDisplayMediaManagerScreen.current || !isMediaConfigured);

	if (!nicknameSeen) {
		return (
			<StudioLoaderCentered>
				<ResourceAccessNickname
					onContinue={() => {
						setInitialNickname('');
					}}
				/>
			</StudioLoaderCentered>
		);
	}

	if (showMediaManagerScreen || isStudioPending) {
		return (
			<MediaManagerScreen
				onClickConfirm={handleConfirmMediaManagerScreen}
				pending={isStudioPending}
				joinAutomatically={joinAutomatically}
			/>
		);
	}

	return (
		<ReadyComponent
			channelId={channelId}
			role={role}
		/>
	);
};

StudioLoaderAllowedAccess.propTypes = {
	pendingTitle: PropTypes.string,
	readyComponent: PropTypes.elementType.isRequired,
	readyTitle: PropTypes.string,
	role: PropTypes.oneOf(Object.values(ResourceAccessRole)).isRequired,
	setInitialNickname: PropTypes.func.isRequired,
	studio: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		owner: PropTypes.shape({
			_id: PropTypes.string.isRequired,
		}),
		status: PropTypes.string.isRequired,
	}).isRequired,
	joinAutomatically: PropTypes.bool,
};

StudioLoaderAllowedAccess.defaultProps = {
	pendingTitle: undefined,
	readyTitle: undefined,
	joinAutomatically: false,
};

const StudioLoaderLoaded = ({
	accessStatus,
	nicknameSeen,
	pendingTitle,
	readyComponent,
	readyTitle,
	requestStudio,
	role,
	setInitialNickname,
	setRequestParams,
	stoppedComponent: StoppedComponent,
	studio,
	joinAutomatically,
}) => {
	const isStudioPublic = studio?.isPublic;
	const isStudioCancelled = studio?.status === StudioStatus.CANCELLED;
	const isStudioPlanned = studio?.status === StudioStatus.PLANNED;
	const isStudioStopped = studio?.status === StudioStatus.STOPPED;
	const isStudioWaitingForController = !studio?.hasControllerJoined;
	// Until the host (or operator) has not joined the studio, we show
	// a waiting page (except for host and operator)
	const waitForController = ![ResourceAccessRole.OWNER, ResourceAccessRole.OPERATOR].includes(role)
		&& isStudioWaitingForController;

	if (isStudioCancelled) return <StudioCancelled />;
	if (isStudioPlanned || waitForController) return <StudioPlanned />;

	const isRedirectOnChannelOnStop = isStudioPublic
		&& role !== ResourceAccessRole.OWNER
		&& studio?.owner?.hashtag;
	if (isStudioStopped) {
		return isRedirectOnChannelOnStop
			? <Redirect to={getChannelLink(studio.owner.hashtag)} />
			: <StoppedComponent />;
	}

	if (!accessStatus.isAccessGranted) {
		return (
			<StudioLoaderForbiddenAccess
				accessStatus={accessStatus}
				requestStudio={requestStudio}
				role={role}
				setRequestParams={setRequestParams}
				studio={studio}
				joinAutomatically={joinAutomatically}
			/>
		);
	}

	return (
		<StudioLoaderAllowedAccess
			nicknameSeen={nicknameSeen}
			pendingTitle={pendingTitle}
			readyTitle={readyTitle}
			readyComponent={readyComponent}
			role={role}
			setInitialNickname={setInitialNickname}
			studio={studio}
			joinAutomatically={joinAutomatically}
		/>
	);
};

StudioLoaderLoaded.propTypes = {
	accessStatus: PropTypes.shape({
		isAccessGranted: PropTypes.bool.isRequired,
	}).isRequired,
	pendingTitle: PropTypes.string,
	readyComponent: PropTypes.elementType.isRequired,
	readyTitle: PropTypes.string,
	requestStudio: PropTypes.func.isRequired,
	role: PropTypes.oneOf(Object.values(ResourceAccessRole)).isRequired,
	setInitialNickname: PropTypes.func.isRequired,
	stoppedComponent: PropTypes.elementType.isRequired,
	studio: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		owner: PropTypes.shape({
			_id: PropTypes.string.isRequired,
			hashtag: PropTypes.string.isRequired,
		}),
		status: PropTypes.string.isRequired,
		hasControllerJoined: PropTypes.bool.isRequired,
		isPublic: PropTypes.bool,
	}).isRequired,
	joinAutomatically: PropTypes.bool,
};

StudioLoaderLoaded.defaultProps = {
	pendingTitle: undefined,
	readyTitle: undefined,
	joinAutomatically: false,
};

export const StudioLoader = ({
	notFoundComponent: NotFoundComponent,
	pendingTitle,
	readyComponent,
	readyTitle,
	role,
	stoppedComponent,
	joinAutomatically,
}) => {
	const { params: { code } } = useRouteMatch();
	const { isGuest, guest } = useAuthentication();
	const [initialNickname, setInitialNickname] = useState(guest?.nickname);
	const [requestParams, setRequestParams] = useState();

	const {
		accessStatus,
		error,
		nickname,
		requestStudio,
		loading,
		studio,
	} = useStudioLoader({ code, requestParams, role });

	const guestNicknameSeen = !isGuest || nickname !== initialNickname;

	const {
		handleWsError,
		wsError,
	} = useWsConnectorError({
		callback: requestStudio,
	}, [code, role]);

	const nicknameRef = useRef(guest?.nickname);
	nicknameRef.current = guest?.nickname;

	useEffect(() => {
		// Reset initial nickname when the studio changes
		setInitialNickname(nicknameRef.current);
	}, [code]);

	const anyError = wsError || error;
	if (anyError) return <StudioLoaderError error={anyError} notFoundComponent={NotFoundComponent} />;
	if (loading) return <Loading />;

	const { owner: channel } = studio;

	const socketRole = (
		accessStatus.isAccessGranted
		&& guestNicknameSeen // Avoid connection before change nickname has been asked to guest
		// in order to avoid the force join modal
	) ? role : ResourceAccessRole.PUBLIC;

	const token = socketRole !== ResourceAccessRole.PUBLIC
		? accessStatus.resourceAccessToken
		: undefined;

	return (
		<ChannelSocketWrapper
			channelId={channel._id}
			role={socketRole}
			studioId={studio._id}
			token={token}
			onWsError={handleWsError}
		>
			<StudioLoaderLoaded
				accessStatus={accessStatus}
				nicknameSeen={guestNicknameSeen}
				pendingTitle={pendingTitle}
				readyTitle={readyTitle}
				readyComponent={readyComponent}
				requestStudio={requestStudio}
				role={role}
				setInitialNickname={setInitialNickname}
				setRequestParams={setRequestParams}
				stoppedComponent={stoppedComponent}
				studio={studio}
				joinAutomatically={joinAutomatically}
			/>
		</ChannelSocketWrapper>
	);
};

StudioLoader.propTypes = {
	notFoundComponent: PropTypes.elementType.isRequired,
	pendingTitle: PropTypes.string.isRequired,
	readyComponent: PropTypes.elementType.isRequired,
	readyTitle: PropTypes.string.isRequired,
	role: PropTypes.oneOf(Object.values(ResourceAccessRole)).isRequired,
	stoppedComponent: PropTypes.elementType.isRequired,
	joinAutomatically: PropTypes.bool,
};

StudioLoader.defaultProps = {
	joinAutomatically: false,
};
